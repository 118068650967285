export const formFields = [
    // Personal Information
    {
        name: 'email',
        label: 'Email',
        type: 'email',
        placeholder: 'Enter your email',
        required: true,
        isDisabled: true,
        validation: {
            regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            errorMessage: 'Invalid email format',
        },
        maxLength: 30,
        row: 1,
        col: 6,
    },
    {
        name: 'title',
        label: 'Title',
        type: 'select',
        options: ['Dr', 'Mr', 'Miss', 'Mrs'],
        required: true,
        row: 2,
        col: 3,
    },
    {
        name: 'firstName',
        label: 'First Name',
        type: 'text',
        placeholder: 'Enter your first name',
        required: true,
        validation: {
            regex: /^[a-zA-Z\s]+$/,
            errorMessage: 'Only alphabets allowed',
        },
        maxLength: 50,
        row: 2,
        col: 3,
        className: 'custom-first-name',
    },
    {
        name: 'middleName',
        label: 'Middle Name',
        type: 'text',
        placeholder: 'Enter your middle name',
        required: false,
        validation: {
            regex: /^[a-zA-Z\s]+$/,
            errorMessage: 'Only alphabets allowed',
        },
        maxLength: 50,
        row: 2,
        col: 3,
        className: 'custom-middle-name',
    },
    {
        name: 'lastName',
        label: 'Last Name',
        type: 'text',
        placeholder: 'Enter your last name',
        required: true,
        validation: {
            regex: /^[a-zA-Z\s]+$/,
            errorMessage: 'Only alphabets allowed',
        },
        maxLength: 50,
        row: 2,
        col: 3,
    },
    {
        name: 'dob',
        label: 'Date of Birth',
        type: 'date',
        required: true,
        validation: {
            custom: (value) => new Date().getFullYear() - new Date(value).getFullYear() >= 18,
            errorMessage: 'Must be 18 years or older',
        },
        row: 3,
        col: 3,
    },
    {
        name: 'gender',
        label: 'Gender',
        type: 'select',
        options: ['Male', 'Female', 'Other'],
        required: true,
        row: 3,
        col: 3,
    },
    {
        name: 'mobile',
        label: 'Mobile Number 1',
        type: 'text',
        placeholder: 'Enter your mobile number',
        required: true,
        validation: {
            regex: /^[0-9]{10}$/,
            errorMessage: 'Invalid mobile number',
        },
        maxLength: 10,
        row: 3,
        col: 3,
    },
    {
        name: 'mobile1',
        label: 'Mobile Number 2',
        type: 'text',
        placeholder: 'Enter your mobile number',
        required: false,
        validation: {
            regex: /^[0-9]{10}$/,
            errorMessage: 'Invalid mobile number',
        },
        maxLength: 10,
        row: 3,
        col: 3,
    },
    {
        name: 'desgId',
        label: 'Designation',
        type: 'select',
        options: [], // Populate dynamically
        required: true,
        row: 4,
        col: 6,
    },
    {
        name: 'qualId',
        label: 'Qualification',
        type: 'select',
        options: [], // Populate dynamically
        required: true,
        row: 4,
        col: 6,
    },
    {
        name: 'idproofNumber',
        label: 'Academic Bank  of Credit ID Number',
        type: 'text',
        placeholder: 'Enter your Credit ID Number',
        validation: {
            regex: /^[0-9 \s]{2,15}$/,
            errorMessage: 'Invalid format',
        },
        requireDependencies: {
            field: 'desgId',
            condition: (value) => value === '19',
        },
        maxLength: 30,
        infoTop: {
            // message: "",
            link: {
                text: "Click here to Download reference PDF",
                url: process.env.PUBLIC_URL + `/assets/images/IDpresentation.pdf`,
            },
        },
        row: 5,
        col: 6,
    },
    {
        name: 'orgId',
        label: 'Organization',
        type: 'select',
        options: [], // Populate dynamically
        required: true,
        row: 5,
        col: 6,
    },
    {
        name: 'instituteName',
        label: 'Organization Name',
        type: 'text',
        placeholder: 'Enter your organization name',
        requireDependencies: {
            field: 'orgId',
            condition: (value) => value === '1',
        },
        visibilityDependencies: {
            field: 'orgId',
            condition: (value) => value === '1',
        },
        validation: {
            regex: /^[A-Za-z()& \s]{2,250}$/,
            errorMessage: 'Enter a correct Organization',
        },
        row: 6,
        col: 6,
    },
    {
        name: 'pincode',
        label: 'Pincode',
        type: 'text',
        placeholder: 'Pincode',
        required: true,
        validation: {
            regex: /^[1-9][0-9]{5}$/,
            errorMessage: 'Enter a valid 6-digit Pincode',
        },
        row: 7,
        col: 3,
    },
    {
        name: 'countryId',
        label: 'Country',
        type: 'select',
        options: [], // Populate dynamically
        required: true,
        row: 7,
        col: 3,
    },
    {
        name: 'stateId',
        label: 'State',
        type: 'select',
        options: [], // Populate dynamically
        required: true,
        visibilityDependencies: {
            field: 'countryId',
            condition: (value) => value !== '2', // Show when countryId has a specific value (!==) means it will be visible for anything but 2
        },
        row: 7,
        col: 3,
    },
    {
        name: 'otherCountry',
        label: 'Country Name',
        type: 'text',
        placeholder: 'Country',
        required: false,
        validation: {
            regex: /^[a-zA-Z\s]+$/,
            errorMessage: 'Enter your Country',
        },
        visibilityDependencies: {
            field: 'countryId',
            condition: (value) => value === '2', // Show when countryId has a specific value of 2 i.e. Other Country
        },
        requireDependencies: {
            field: 'countryId',
            condition: (value) => value === '2',
        },
        maxLength: 65,
        row: 7,
        col: 3,
    },
    {
        name: 'city',
        label: 'City',
        type: 'text',
        placeholder: 'City',
        required: true,
        validation: {
            regex: /^[a-zA-Z\s]+$/,
            errorMessage: 'Enter your City',
        },
        maxLength: 45,
        row: 7,
        col: 3,
    },
    {
        name: 'address',
        label: 'Address',
        type: 'text',
        placeholder: 'Address',
        required: false,
        validation: {
            regex: /^[a-zA-Z0-9\s,.\-/\\'&()]+$/,
            errorMessage: 'Enter a valid Address',
        },
        infoBottom: {
            message: "AlphaNumeric and Special character like ( ) , . - ' & are allowed",
        },
        maxLength: 100,
        row: 8,
        col: 12,
    },
    {
        name: 'facebookId',
        label: 'Facebook ID',
        type: 'text',
        placeholder: 'Facebook ID',
        required: false,
        validation: {
            regex: /^[a-zA-Z0-9._-]+$/,
            errorMessage: 'Enter a valid Facebook ID. Only letters, numbers, periods, underscores, and dashes are allowed.',
        },
        maxLength: 50,
        row: 9,
        col: 4,
    },
    {
        name: 'linkedinId',
        label: 'Linkedin ID',
        type: 'text',
        placeholder: 'Linkedin ID',
        required: false,
        validation: {
            regex: /^[a-zA-Z0-9._-]+$/,
            errorMessage: 'Enter a valid Linkedin ID. Only letters, numbers, periods, underscores, and dashes are allowed.',
        },
        maxLength: 50,
        row: 9,
        col: 4,
    },
    {
        name: 'twitterId',
        label: 'Twitter(X) ID',
        type: 'text',
        placeholder: 'Twitter(X) ID',
        required: false,
        validation: {
            regex: /^[a-zA-Z0-9._-]+$/,
            errorMessage: 'Enter a valid twitter ID. Only letters, numbers, periods, underscores, and dashes are allowed.',
        },
        maxLength: 15,
        row: 9,
        col: 4,
    },
    {
        name: 'youtubeId',
        label: 'Youtube ID',
        type: 'text',
        placeholder: 'Youtube ID',
        required: false,
        validation: {
            regex: /^[a-zA-Z0-9._-]+$/,
            errorMessage: 'Enter a valid Youtube ID. Only letters, numbers, periods, underscores, and dashes are allowed.',
        },
        maxLength: 24,
        row: 10,
        col: 6,
    },
    {
        name: 'skypeId',
        label: 'Skype ID',
        type: 'text',
        placeholder: 'Skype ID',
        required: false,
        validation: {
            regex: /^[a-zA-Z0-9._-]+$/,
            errorMessage: 'Enter a valid Skype ID. Only letters, numbers, periods, underscores, and dashes are allowed.',
        },
        maxLength: 32,
        row: 10,
        col: 6,
    },


    ////// CONDITIONAL ENABLE / DISABLE FIELDS


    ////// CONDITIONAL VISIBILITY OF FIELDS
    ////// PLACE HOLDER TO SHOW CONDITIONAL VISIBILITY
    // { 
    //     name: 'organizationName',
    //     label: 'Organization Name',
    //     type: 'text',
    //     placeholder: 'Enter your organization name',
    //     required: true,
    //     disabled: true, // Initially disabled
    //     enabledDependencies: {
    //         field: 'userCategory',
    //         condition: (value) => value !== '', // Enable when userCategory is not empty
    //     },
    //     visibilityDependencies: {
    //         field: 'userCategory',
    //         condition: (value) => value === 'SpecificValue', // Show when userCategory has a specific value
    //     },
    //     row: 4,
    //     col: 6,
    // },
    // {
    //     name: 'aadharNumber',
    //     label: 'Aadhar Number',
    //     type: 'text',
    //     placeholder: 'Enter your Aadhar number',
    //     required: false,
    //     validation: {
    //         regex: /^[2-9]{1}[0-9]{11}$/,
    //         errorMessage: 'Invalid Aadhar number',
    //     },
    //     maxLength: 12,
    //     row: 6,
    //     col: 6,
    // },
    // {
    //     name: 'gstNumber',
    //     label: 'GST Number',
    //     type: 'text',
    //     placeholder: 'Enter your GST number',
    //     required: false,
    //     validation: {
    //         regex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    //         errorMessage: 'Invalid GST number',
    //     },
    //     maxLength: 15,
    //     row: 6,
    //     col: 6,
    // },
    // {
    //     name: 'address',
    //     label: 'Address',
    //     type: 'textarea',
    //     placeholder: 'Enter your address',
    //     required: true,
    //     maxLength: 200,
    //     row: 7,
    //     col: 12,
    // },

    // {
    //     name: 'organizationName',
    //     label: 'Organization Name',
    //     type: 'text',
    //     placeholder: 'Enter your organization name',
    //     required: true,
    //     disabled: true, // Initially disabled
    //     enabledDependencies: {
    //         field: 'userCategory',
    //         condition: (value) => value !== '', // Enable when userCategory is not empty
    //     },
    //     row: 6,
    //     col: 6,
    // },
    // {
    //     name: 'userDesignation',
    //     label: 'Designation',
    //     type: 'text',
    //     placeholder: 'Enter your designation',
    //     required: false,
    //     maxLength: 50,
    //     row: 6,
    //     col: 6,
    // },

];
