import React, { Component, useEffect, useState } from 'react';
import infographicImage from "../assets/images/infographic.png";
import { Image } from 'react-bootstrap';

// export default function InfographicShow() {
//   return (
//     <div className="bg-white py-5 px-3 text-center">
//       <div className="d-flex justify-content-center p-4">
//         <Image
//           src={infographicImage}
//           alt="Course Completion Process"
//           className="rounded img-fluid"
//           style={{ maxWidth: '1200px'}}
//         //   style={{ maxWidth: '1200px', transition: 'transform 0.3s', cursor: 'pointer' }}
//         //   onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
//         //   onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
//         />
//       </div>
//     </div>
//   );
// }

export default function InfographicShow() {
    const [isEnlarged, setIsEnlarged] = useState(false);
  
    const toggleEnlarged = () => {
      setIsEnlarged(!isEnlarged);
    };
  
    return (
      <div className="bg-white px-2 px-md-4 text-center" style={{ paddingTop: '70px' }}>
        <div className="container">
          <div className="row justify-content-center">
            <div >
              <div
                className={`mx-auto overflow-hidden rounded-lg shadow-md transition-all duration-300`}
                onClick={isEnlarged ? toggleEnlarged : undefined}
              >
                <div className={`relative w-full`}>
                  <Image
                    src={infographicImage}
                    alt="Course Completion Process"
                    className={`rounded  hover:opacity-95 transition-opacity img-fluid`}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    );
  }
