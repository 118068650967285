import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, OverlayTrigger, Row, Tab, Table, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledFileInput, StyledFormGroup, StyledFormLabel, StyledTabs, TabContent } from './AssignmentDeliveryStyles';
import AssignmentService, { ASSIGN_URI } from '../../../../services/AssignmentService';
import DataTable from 'react-data-table-component';
import useNonce from '../../../../helper/Nonce';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import md5 from 'md5';
import { Tooltip } from 'chart.js';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';


//styles , icons and columns
const fileIcons = {
    zip: { icon: "fas fa-file-archive text-warning fa-3x" },
    pdf: { icon: "fas fa-file-pdf text-danger fa-3x" },
    doc: { icon: "fas fa-file-word text-info fa-3x" },
    docx: { icon: "fas fa-file-word text-primary fa-3x" }
};
const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        }
    },

    rows: {
        style: {
            minHeight: '72px'
        },
    },

    headCells: {
        style: {

            widthRight: '8px',
            widthLeft: '8px',
            // paddingLeft: '8px', // override the cell padding for head cells
            // paddingRight: '8px',
            fontSize: '17px',
            // fontWeight: '500',
            // textTransform: 'uppercase',
            // paddingLeft: '0 8px',
            // marginLeft: '10px',
        },
    },
    cells: {
        style: {
            widthRight: '8px',
            widthLeft: '8px',
            // paddingLeft: '8px', // override the cell padding for data cells
            // paddingRight: '8px',
            fontSize: '15px',
            // paddingLeft: '0 8px',
            // marginLeft: '10px'
        },
    },
};
const viewAssignFileColumn = [
    {
        name: 'File Name',
        selector: row => row.filename,
    },
    {
        name: "Preview",
        cell: (row) => <FilePreview filename={row.filename} id={row.id} viewFileType={"assignment"} />
    },
];
const uploadSolutionColumns = (handleDeleteFile, getAssignDetails, setAssignDetails) => [
    {
        name: 'File Name',
        selector: row => row.filename
    },
    {
        name: 'Preview',
        selector: row => <FilePreview filename={row.filename} id={row.sno} viewFileType={"solution"} />
    },
    {
        name: '',
        selector: row =>
            <>
                {
                    getAssignDetails?.solutionDetails?.status == 'submitted'
                        ?
                        <>
                            <span className='text-success'>Assignment is submitted</span>
                        </>
                        :
                        <>
                            {
                                getAssignDetails?.solutionDetails?.status === "reject" ?
                                    <>
                                        <Button variant='outline-danger' onClick={() => { handleDeleteFile(row.sno, getAssignDetails, setAssignDetails) }}><i className="fas fa-trash"></i> </Button>
                                        <br />
                                        <span>Delete the solution file before uploading</span>
                                    </>
                                    :
                                    <>
                                        {/* {
                                            getAssignDetails?.solutionDetails?.marks <= getAssignDetails?.solutionDetails?.passMarks ?
                                                <>
                                                    <Button variant='outline-danger' onClick={() => { handleDeleteFile(row.sno, getAssignDetails, setAssignDetails) }}><i className="fas fa-trash"></i> </Button>
                                                </>
                                                :
                                                <>
                                                </>
                                        } */}
                                    </>
                            }
                        </>
                }
            </>,
        wrap: "true"
    },
]

//Internal components
const FilePreview = ({ filename, id, viewFileType }) => {

    const handleSaveFile = async (uri, filename, fileId) => {
        try {


            const response = await AssignmentService.getfileView(uri, fileId);

            //    alert("dfafsd")
            if (filename.match(".zip")) { saveAs(response.data, filename) }
            else if (filename.match(".pdf")) {
                saveAs(response.data, filename)
            }
            else if (filename.match(".doc") || filename.match(".docx")) { saveAs(response.data, filename) }
        } catch (error) {
            if (error) {
                swal("Information", "Assignment File does not Exist!", "info");
            }
            // console.error("error", error)
        }
    }
    // viewFileType = assignment, solution
    const iconData = handleGetFileIcon(filename);
    let previewUrl = viewFileType === 'assignment' ? ASSIGN_URI._GET_FILE_TO_VIEW : ASSIGN_URI._GET_STUDENT_FILE

    // let previewUrl = viewFileType === 'assignment' 
    // ? encodeURIComponent(ASSIGN_URI._GET_FILE_TO_VIEW) 
    // : encodeURIComponent(ASSIGN_URI._GET_STUDENT_FILE);

    if (!iconData) return null;
    return (
        // <a href={`${previewUrl}${id}`} rel="noopener noreferrer">
        <div style={{ cursor: "pointer" }} onClick={() => { handleSaveFile(previewUrl, filename, id) }} rel="noopener noreferrer">
            <i className={iconData.icon}></i>
        </div>
        // </a>
    );
};
const CustomFileInput = ({ handleSelectedFileChange, handleUploadSolution, isUploading }) => {

    return (
        <StyledFileInput>
            <input type='file' id='custom-file-upload' className='custom-file-upload' onChange={handleSelectedFileChange} />
            <Button className='create-button' onClick={handleUploadSolution} disabled={isUploading}>
                <i className="fa fa-upload" aria-hidden="true"></i>
                <span>{isUploading ? "Uploading..." : "Upload"}</span>
            </Button>
        </StyledFileInput>
    );
};

//handler's
const handleGetFileIcon = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return fileIcons[extension] || null;
};
const handleGetAssignmentDetails = (getAssignDetails, setAssignDetails) => {
    //get assignment by assigmentID
    AssignmentService.getAssignmentData(getAssignDetails.assignmentId).
        then((resp) => {
            setAssignDetails((prevState) => {
                return {
                    ...prevState,
                    assignmentFileList: [...(resp.data.assignmentFilePath)],
                    assignDetails: resp.data
                }
            })
            // setAssignmentFileList(resp.data.assignmentFilePath);

        }).catch((error) => {

        })
}


const handleSelectedFileChange = (e, t, setAssignDetails) => {
    let files = e.target.files;
    const fileInput = document.getElementById('custom-file-upload');

    for (const file of files) {
        const fileName = file.name;
        const fileSize = file.size;
        const allowedTypes = ["application/pdf", "application/x-pdf", "application/x-zip-compressed"];
        const allowedExtensions = ["pdf", "zip"];
        const extensions = fileName.toLowerCase().split('.');

        // Check file type
        if (!allowedTypes.includes(file.type)) {
            setAssignDetails(prevState => ({ ...prevState, errorType: t('assign_upload_err') }));
            alert("Invalid file type, only PDF and Zip files are allowed");
            if (fileInput) {
                fileInput.value = '';
            }
            return;
        }

        // Check file size (should be less than 10MB)
        if (fileSize > 10 * 1024 * 1024) {
            setAssignDetails(prevState => ({ ...prevState, errorType: t('assign_size_err') }));
            alert("File size should be less than 10MB");
            if (fileInput) {
                fileInput.value = '';
            }
            return;
        }

        // Check for double extension (e.g., "file.pdf.exe")
        if (extensions.length > 2 || !allowedExtensions.includes(extensions.pop())) {
            setAssignDetails(prevState => ({ ...prevState, errorType: t('assign_double_ext_err') }));
            alert("The file name should not contain multiple extensions (e.g., 'document.1.pdf'). Please use a single valid extension (e.g., 'document 1.pdf').");
            if (fileInput) {
                fileInput.value = '';
            }
            return;
        }

        // If all checks pass, update the state
        setAssignDetails(prevState => ({
            ...prevState,
            fileCheck: true,
            selectedFiles: files
        }));
    }
};

const handleDeleteFile = (id, getAssignDetails, setAssignDetails) => {
    AssignmentService.solutionfileonlydelete(id).then((resp) => {
        if (resp.status === 200) {
            swal('Success', 'File Deleted Successfully!', 'success');
            handleGetUploadAssignDetails(getAssignDetails, setAssignDetails);
            window.location.reload();
        }
    }).catch((err) => {
        console.error("Error deleting file:", err);
    })
}



const handleUploadSolution = (t, getAssignDetails, setAssignDetails, isUploading, setIsUploading) => {

    if (isUploading) {
        return;
    }

    // console.log("getAssignDetails =====>", getAssignDetails);


    if (getAssignDetails.fileCheck) {
        const fileInput = document.getElementById('custom-file-upload');
        const form = new FormData();
        for (const file of getAssignDetails.selectedFiles) {
            form.append('file', file);
            form.append('filehash', md5((file.size).toString()))
        }
        const startDate = new Date(getAssignDetails?.assignDetails?.createdDate);

        let endDate = "";
        if (getAssignDetails?.solution?.extensionDate) {
            endDate = new Date(getAssignDetails.solution.extensionDate);
        } else {
            endDate = new Date(getAssignDetails?.assignDetails?.closingDate);
        }

        // console.log("endDate ===>", endDate);

        const currentDate = new Date();

        if (!(currentDate >= startDate && currentDate <= endDate)) {

            swal('Warning', 'Please Check the Start and End date of the Assignment!', 'warning');
            if (fileInput) {
                fileInput.value = '';
            }
            setAssignDetails(prevState => ({ ...prevState, fileCheck: false }))
            //console.log(true);
            return;
        }

        Swal.fire({
            title: "Are you sure you want to submit the Assignment ?",
            text: "Once the Assignment is submitted can not be resubmited until it is reject by the Instructor",
            showConfirmButton: true,
            showCancelButton: true,
            icon: "info",
        }).then((result) => {
            if (result.isConfirmed) {

                setIsUploading(true);

                Swal.fire({
                    title: "Please Wait",
                    text: "File is uploading",
                    didOpen: () => {
                        Swal.showLoading()
                        AssignmentService.postStudentFileUpload(getAssignDetails.assignmentId, getAssignDetails.userId, form)
                            .then((res) => {
                                setIsUploading(false);
                                if (res.data === "InvalidFile") {
                                    Swal.fire({
                                        title: t('error'),
                                        text: t('file_not_support'),
                                        icon: "success",
                                    })
                                }
                                else if (res.data === "ReSubmitError") {
                                    Swal.fire({
                                        title: t('resubmiterror'),
                                        text: t('resubmitmsg'),
                                        icon: "warning",
                                    })
                                    if (fileInput) {
                                        fileInput.value = '';
                                    }
                                } else if (res.data === "ASSIGNMENT_SUBMISSION TIME_EXCEEDED") {
                                    Swal.fire({
                                        title: "Failed",
                                        text: "Assignment Submission Time Exceeded",
                                        icon: "warning",
                                    })
                                    if (fileInput) {
                                        fileInput.value = '';
                                    }
                                }
                                else {
                                    Swal.fire({
                                        title: t('success'),
                                        text: t('file_upload_msg'),
                                        icon: "success",
                                    })
                                    handleGetUploadAssignDetails(getAssignDetails, setAssignDetails);
                                    if (fileInput) {
                                        fileInput.value = '';
                                    }
                                    window.location.reload();
                                }
                                setAssignDetails(prevState => ({ ...prevState, fileCheck: false }))
                            }).catch((error) => {
                                // console.log("Error:", error);
                                setIsUploading(false);
                                Swal.fire({
                                    title: "error",
                                    text: "Internal Server Error! Try Again Later",
                                    icon: "error",
                                })
                                console.error("Error uploading file:", error);
                            })
                    }
                })

            }
            else {

            }
        })

    }
}
const handleGetUploadAssignDetails = (getAssignDetails, setAssignDetails) => {
    //service Called here to get the data

    // await fetchAndSetNonce("Assignment");
    AssignmentService.getStudentFileUpload(getAssignDetails.assignmentId, getAssignDetails.userId)
        .then((resp) => {

            //console.log("comming in sol file")
            if (resp.data.solution !== null) {
                setAssignDetails((prevState) => ({
                    ...prevState,
                    solution: resp.data?.solution,
                }))

            }
            if (resp.data.solutionmarks !== null) {
                setAssignDetails((prevState) => ({
                    ...prevState,
                    solutionmarks: resp.data?.solutionmarks,
                }))

            }
            if (resp.data.solutionfile !== null) {
                setAssignDetails((prevState) => ({
                    ...prevState,
                    solutionFiles: resp.data?.solutionfile,
                    solutionDetails: resp.data.solutionmarks
                }))

            }
            if (resp.data.solutionfile === null) {
                setAssignDetails((prevState) => ({
                    ...prevState,
                    solutionFiles: [],
                    solutionDetails: null
                }))
            }

        }).catch((err) => {


        })
}

//View Assignment Component
export const AssignmentDetails = ({ getAssignDetails }) => {
    return (
        <Card className='w-100 text-center'>
            <Card.Body>
                <Card.Title>{getAssignDetails.assignDetails?.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{getAssignDetails.assignDetails?.description}</Card.Subtitle>
                <Card.Text>
                    <div className='border mt-3'>
                        <DataTable
                            columns={viewAssignFileColumn}
                            data={getAssignDetails?.assignmentFileList}
                            customStyles={customStyles}
                            striped
                            highlightOnHover
                        />
                    </div>
                </Card.Text>
                {/* <Card.Link href="#">Card Link</Card.Link>
                                <Card.Link href="#">Another Link</Card.Link> */}
            </Card.Body>
        </Card>


    )
}


//Upload Assignment Component
export const UploadSolution = ({ getAssignDetails, setAssignDetails }) => {
    const { t } = useTranslation()
    // useEffect(() => {

    const UploadSolutoncolumns = uploadSolutionColumns(handleDeleteFile, getAssignDetails, setAssignDetails);

    const [isUploading, setIsUploading] = useState(false);

    return (
        <Card className='w-100 text-center'>
            <Card.Body>
                <Card.Text>
                    {
                        getAssignDetails?.solutionDetails === null ?
                            <>
                                <Card.Title>{t('upload_file')}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{t('file_pdf_doc_zip')}</Card.Subtitle>
                                <CustomFileInput
                                    handleSelectedFileChange={(e) => { handleSelectedFileChange(e, t, setAssignDetails) }}
                                    handleUploadSolution={() => { handleUploadSolution(t, getAssignDetails, setAssignDetails, isUploading, setIsUploading) }}
                                    isUploading={isUploading}
                                />
                                <Card.Subtitle className="mb-2 text-muted" style={{ fontWeight: "bold" }}><span style={{ color: "red" }}>*</span> Once the assignment solution is uploaded, you are not allowed to submit again.</Card.Subtitle>
                                <Card.Subtitle className="mb-2 text-muted" style={{ fontWeight: "bold" }}><span style={{ color: "red" }}>*</span> The file name should not contain multiple extensions e.g., 'document.1.pdf'. Please use a single valid extension e.g., 'document 1.pdf'.</Card.Subtitle>
                                <Card.Subtitle className="mb-2 text-muted" style={{ fontWeight: "bold" }}><span style={{ color: "red" }}>*</span> File size should be less than 10MB</Card.Subtitle>
                            </>
                            :
                            <>
                                {
                                    getAssignDetails?.solutionDetails?.status === "reupload" ?
                                        <>
                                            <Card.Title>{t('upload_file')}</Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">{t('file_pdf_doc_zip')}</Card.Subtitle>
                                            <CustomFileInput
                                                handleSelectedFileChange={(e) => { handleSelectedFileChange(e, t, setAssignDetails) }}
                                                handleUploadSolution={() => { handleUploadSolution(t, getAssignDetails, setAssignDetails, isUploading, setIsUploading) }}
                                                isUploading={isUploading}
                                            />
                                            <Card.Subtitle className="mb-2 text-muted" style={{ fontWeight: "bold" }}><span style={{ color: "red" }}>*</span> Once the assignment solution is uploaded, you are not allowed to submit again.</Card.Subtitle>
                                            <Card.Subtitle className="mb-2 text-muted" style={{ fontWeight: "bold" }}><span style={{ color: "red" }}>*</span> The file name should not contain multiple extensions e.g., 'document.1.pdf'. Please use a single valid extension e.g., 'document 1.pdf'.</Card.Subtitle>
                                            <Card.Subtitle className="mb-2 text-muted" style={{ fontWeight: "bold" }}><span style={{ color: "red" }}>*</span> File size should be less than 10MB</Card.Subtitle>

                                        </>
                                        :
                                        <>
                                        </>
                                }
                            </>
                    }
                    <div className='border mt-3'>
                        <DataTable
                            columns={UploadSolutoncolumns}
                            data={getAssignDetails?.solutionFiles}
                            customStyles={customStyles}
                            striped
                            highlightOnHover
                        />
                    </div>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export const AssignmentStatus = ({ getAssignDetails, setAssignDetails }) => {

    // console.log("getAssignDetails====>", getAssignDetails);

    const convertTimestampToDate = (timestamp) => {
        return moment(timestamp).format('Do MMMM YYYY');
    };


    return (
        <Row className="justify-content-md-center w-100">
            <Col xs={12} md={6}>
                <Table className="table table-borderless bg-white rounded" responsive>
                    {getAssignDetails.solutionDetails !== null && (
                        <>
                            {
                                getAssignDetails?.solutionDetails?.status === "evaluated" ?
                                    <>
                                        <tbody>
                                            <tr>
                                                <td className='text-capitalize font-weight-bold text-start'>Marks Achieved</td>
                                                <td className='text-capitalize font-weight-bold px-2'>:</td>
                                                <td>{getAssignDetails.solutionDetails?.marks}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize font-weight-bold text-start'>Remarks</td>
                                                <td className='text-capitalize font-weight-bold px-2'>:</td>
                                                <td className='text-capitalize'>{getAssignDetails.solutionDetails?.remarks}</td>
                                            </tr>
                                            <tr>
                                                <td className='text-capitalize font-weight-bold text-start'>Status</td>
                                                <td className='text-capitalize font-weight-bold px-2'>:</td>
                                                <td className='text-capitalize'>{getAssignDetails.solutionDetails?.status}</td>
                                            </tr>
                                        </tbody>
                                    </>
                                    :
                                    <>
                                        {
                                            getAssignDetails?.solutionDetails?.status === "approve" ?
                                                <>
                                                    <tbody>
                                                        <tr>
                                                            <td className='text-capitalize font-weight-bold text-start'>Status</td>
                                                            <td className='text-capitalize font-weight-bold px-2'>:</td>
                                                            <td className='text-capitalize'>{getAssignDetails.solutionDetails?.status === "approve" ? "Approved" : ""}</td>
                                                        </tr>
                                                    </tbody>
                                                </>
                                                :
                                                <>
                                                    {
                                                        getAssignDetails?.solutionDetails?.status === "reject" || getAssignDetails?.solutionDetails?.status === "reupload" ?
                                                            <>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='text-capitalize font-weight-bold text-start'>Remarks</td>
                                                                        <td className='text-capitalize font-weight-bold px-2'>:</td>
                                                                        <td className='text-capitalize'>{getAssignDetails.solutionDetails?.remarks}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='text-capitalize font-weight-bold text-start'>Status</td>
                                                                        <td className='text-capitalize font-weight-bold px-2'>:</td>
                                                                        <td className='text-capitalize'>{(getAssignDetails.solutionDetails?.status === "reject" || getAssignDetails.solutionDetails?.status === "reupload") ? "Resubmit" : ""}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='text-capitalize font-weight-bold text-start'>Reupload Before</td>
                                                                        <td className='text-capitalize font-weight-bold px-2'>:</td>
                                                                        <td className='text-capitalize'>{convertTimestampToDate(getAssignDetails.solution?.extensionDate)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </>
                                                            :
                                                            <>
                                                                <div className='text-center'>
                                                                    <h5 className='text-capitalize font-weight-300' style={{ padding: "20px 0px" }}>Not yet evaluated</h5>
                                                                </div>
                                                            </>
                                                    }
                                                </>
                                        }
                                    </>
                            }
                        </>
                    )}
                </Table>
                {
                    getAssignDetails.solutionDetails === null && <Alert className="my-2 text-center" key="assignment" variant="warning">
                        Please Upload the Solution File!
                    </Alert>
                }
            </Col>
        </Row>
    )
}

//Assignment Details Component -- code start's here
export const AssignmentDelivery = ({ assignmentId, userId, userRole }) => {

    const fetchAndSetNonce = useNonce()
    const assignmentDetailsState = {
        assignmentFileList: [],
        assignDetails: null,
        solutionFiles: [],
        solutionDetails: null,
        extensionDate: null,
        extensionCount: null,
        fileCheck: false,
        selectedFiles: [],
        errorType: null,
        assignmentId: null,
        userId: null

    }
    const [getAssignDetails, setAssignDetails] = useState(assignmentDetailsState)

    useEffect(() => {
        setAssignDetails(prevState => ({
            ...prevState,
            assignmentId,
            userId
        }))
    }, [assignmentId, userId])

    // get Assignment detials and solution file details
    useEffect(() => {
        if (getAssignDetails.assignmentId && getAssignDetails.userId) {
            handleGetAssignmentDetails(getAssignDetails, setAssignDetails)
            if (userRole === 'learner') {
                handleGetUploadAssignDetails(getAssignDetails, setAssignDetails)
            }
        }
    }, [getAssignDetails.assignmentId, getAssignDetails.userId])

    useEffect(() => {
        //console.log("getAssignDetails", getAssignDetails)
    }, [getAssignDetails])
    return (
        <div>
            <StyledTabs
                defaultActiveKey="assignment-details"
                id="fill-tab-example"
                className="mb-3"
                fill
            >
                <Tab eventKey="assignment-details" title="Assignment Details" >
                    <TabContent>
                        <AssignmentDetails getAssignDetails={getAssignDetails} />
                    </TabContent>
                </Tab>
                {
                    userRole === 'learner' &&
                    <Tab eventKey="uploaded-assignment-files" title="Upload Solution files">
                        <TabContent>
                            <UploadSolution assignmentId={assignmentId} getAssignDetails={getAssignDetails} setAssignDetails={setAssignDetails} userId={userId} />
                        </TabContent>
                    </Tab>}
                {
                    userRole === 'learner' &&
                    <Tab eventKey="assignment-tatus" title="Assignment Status">
                        <TabContent>
                            <AssignmentStatus assignmentId={assignmentId} getAssignDetails={getAssignDetails} userId={userId} />
                        </TabContent>
                    </Tab>
                }
            </StyledTabs>
        </div>
    );
};