import React, { Component, useEffect, useState } from 'react';
import certificateImage from "../assets/images/certificateMUHS.png";
import { Button, Image, Modal } from 'react-bootstrap';

export default function CertificateSection() {
    const [show, setShow] = useState(false);

    return (
        <div className="text-center my-5">
            <div className="container d-flex justify-content-center">
                {/* <h3 className="mb-4">Certificate Preview</h3> */}
                <Image
                    src={certificateImage}
                    alt="Course Certificate"
                    className="rounded img-fluid cursor-pointer"
                    // onClick={() => setShow(true)}
                    style={{ maxWidth: "100%", cursor: "pointer" }}
                />
            </div>

            {/* Bootstrap Modal for enlarged certificate */}
            <Modal show={show} onHide={() => setShow(false)} centered size="xl" className="certificate-modal">
                <Modal.Header closeButton className="border-0 pb-0">
                    <Modal.Title className="w-100 text-center fw-bold text-primary">CERTIFICATE PREVIEW</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center p-4">
                    <div className="certificate-display bg-light p-3 rounded">
                        <Image
                            src={certificateImage}
                            alt="Certificate"
                            className="img-fluid shadow-sm"
                            style={{ maxWidth: "100%" }}
                        />
                    </div>
                    <p className="text-muted mt-3">
                        This is a sample certificate issued by Maharashtra University of Health Sciences.
                        Your certificate will include your name, course details, and unique verification code.
                    </p>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-center">
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close Preview
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
